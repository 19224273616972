import { ErrorBoundaryProps, ErrorBoundary } from '@sentry/react';
import { Outlet } from 'react-router';
import runtime from '../../../runtime';
import { handleError } from '../../blocks/Error';
import Footer from '../../blocks/Footer';

const beforeCapture: ErrorBoundaryProps['beforeCapture'] = scope => {
    scope.setTag('boundary', 'CenteredLayout');
};

const CenteredLayout = () => (
    <div className="flex h-screen flex-col">
        <div className="flex flex-1 items-center justify-center">
            <ErrorBoundary beforeCapture={beforeCapture} fallback={handleError}>
                <Outlet />
            </ErrorBoundary>
        </div>
        {runtime.organization && (
            <Footer organizationLegalName={runtime.organization.legalName} version={runtime.appVersion} />
        )}
    </div>
);

export default CenteredLayout;
