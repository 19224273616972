
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "BasicAuthenticationResponse": [
      "BasicAuthenticationRequireOtpResponse",
      "BasicAuthenticationResetPasswordResponse",
      "BasicAuthenticationSuccessfulResponse",
      "ErrorResponse"
    ],
    "Broadcast": [
      "DataSourceSyncBroadcast",
      "DummyBroadcast",
      "UserSessionBroadcast"
    ],
    "ChangeEmailResponse": [
      "ChangeEmailSuccessfulResponse",
      "ErrorResponse"
    ],
    "ChangePasswordResponse": [
      "ChangePasswordSuccessfulResponse",
      "ErrorResponse"
    ],
    "ConfirmEmailResponse": [
      "ConfirmEmailSuccessfulResponse",
      "ErrorResponse"
    ],
    "EnableAuthenticatorResponse": [
      "EnableAuthenticatorSuccessfulResponse",
      "ErrorResponse"
    ],
    "InviteResponse": [
      "ErrorResponse",
      "InviteSuccessfulResponse"
    ],
    "RequestNewPasswordResponse": [
      "ErrorResponse",
      "RequestNewPasswordSuccessfulResponse"
    ],
    "ResetPasswordResponse": [
      "ErrorResponse",
      "ResetPasswordSuccessfulResponse"
    ],
    "SSOConfiguration": [
      "MSALConfiguration",
      "OIDCConfiguration"
    ],
    "UpdateGroupResponse": [
      "ErrorResponse",
      "UpdateGroupSuccessfulResponse"
    ],
    "UpdateLeadResponse": [
      "ErrorResponse",
      "UpdateLeadSuccessfulResponse"
    ],
    "UpdateOrganizationBaseInfoResponse": [
      "ErrorResponse",
      "UpdateOrganizationBaseInfoSuccessfulResponse"
    ],
    "UpdateOrganizationCapSettingResponse": [
      "ErrorResponse",
      "UpdateOrganizationCapSettingSuccessfulResponse"
    ],
    "UpdateOrganizationSSOResponse": [
      "ErrorResponse",
      "UpdateOrganizationSSOSuccessfulResponse"
    ],
    "UpdateProfileResponse": [
      "ErrorResponse",
      "UpdateProfileSuccessfulResponse"
    ],
    "VerifyOtpResponse": [
      "ErrorResponse",
      "VerifyOtpSuccessfulResponse"
    ]
  }
};
      export default result;
    