export const primary = {
    bgColor: 'bg-pink-600',
    bgColorTransparent: 'bg-pink-600/10',
    hoverBgColor: 'hover:bg-pink-500',
    hoverTextColor: 'hover:text-pink-500',
    textColor: 'text-pink-600',
    focusRingColor: 'focus:ring-pink-500',
    focusWithinOutlineColor: 'focus-within:outline-pink-500',
    focusVisibleOutlineColor: 'focus-visible:outline-pink-600',
    borderColor: 'border-pink-500',
    dataCheckedBgColor: 'data-[checked]:bg-pink-600',
};

export const secondary = {
    textColor: 'text-gray-900',
    ringColor: 'ring-gray-300',
    hoverBgColor: 'hover:bg-gray-50',
};
